<template>
  <div class="box mt-2">
    <div class="has-text-centered mb-3">
      <span class="title">文件核心</span>
      <br/>
      <span class="subtitle">{{ searchCategory != "" ? searchCategory : "全部" }}</span>
    </div>
    <b-field grouped group-multiline>
      <b-field
        class="pb-2"
        expanded label="主级分类"
        label-position="on-border">
        <b-select
          v-model="searchCategory"
          v-on:input="filterByType(searchCategory)"
          expanded placeholder="选择一级分类">
          <option value="">全部</option>
          <option v-for="(items,index) in searchCategoryList" :key="index" :value="items">
            {{ items }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="pb-2"
        expanded
        label="次级分类"
        label-position="on-border">
        <b-select
          v-model="searchCurrentCategory"
          v-on:input="filterByTypePlus(searchCategory,searchCurrentCategory)"
          :disabled="searchCurrentCategoryList.length==0||searchCategory.length==0"
          expanded
          placeholder="选择二级分类">
          <option value="">全部</option>
          <option v-for="(items,index) in searchCurrentCategoryList" :key="index" :value="items">
            {{ items }}
          </option>
        </b-select>
      </b-field>
      <b-field
        expanded
        class="pb-2"
        label="搜索..."
        label-position="on-border">
        <b-input v-model="searchNameValue" expanded placeholder="Search..." type="search"></b-input>
        <p class="control">
          <b-button @click="filterByName(searchNameValue)" class="button is-primary">搜索</b-button>
        </p>
      </b-field>
    </b-field>

    <b-table
      scrollable
      :data="currentDataList"
      paginated
      per-page="10"
      default-sort="user.first_name"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">

      <b-table-column field="fileName" label="文件名" width="35%" sortable v-slot="props">
        {{ props.row.fileName }}
      </b-table-column>

      <b-table-column field="createDate" width="15%" label="上传时间" sortable centered v-slot="props">
                <span class="tag is-success">
                    {{ new Date(props.row.createDate).toLocaleDateString() }}
                </span>
      </b-table-column>

      <b-table-column field="updateDate" width="15%" label="最后调用" sortable centered v-slot="props">
                <span class="tag is-success">
                    {{ new Date(props.row.updateDate).toLocaleDateString() }}
                </span>
      </b-table-column>


      <b-table-column field="fileSize" width="15%" label="文件大小(MB)" centered sortable v-slot="props">
        {{ (props.row.fileSize / (1024*1024)).toFixed(2) }}
      </b-table-column>

      <b-table-column field="downloadCount" width="15%" label="调用次数" centered sortable v-slot="props">
        {{ props.row.downloadCount }}
      </b-table-column>

      <b-table-column width="5%" label="操作" centered v-slot="props">
        <b-field>
          <b-button size="is-small" @click="confirmCustomDelete(props.row.id)" label="删除" type="is-danger"/>
          <a :href="$store.state.apiHost+'/fileHost/public/download/'+props.row.id"
             type="_blank"
             class="button is-small is-link">下载</a>
        </b-field>
      </b-table-column>
    </b-table>

  </div>
</template>

<script>
import {get, post} from "@/untils/request";

export default {
  name: "adminControllFile",
  data() {
    return {
      dataList: [],
      currentDataList: [],
      useTransition: false,
      searchCategory: "",
      searchCategoryList:[],
      searchCurrentCategory: "",
      searchCurrentCategoryList: [],
      searchNameValue: "",
    }
  },
  methods: {
    // 根据一级类别筛选
    filterByType(fileType) {
      if (fileType.length == 0) {
        this.currentDataList = this.dataList
        this.searchCurrentCategory = ""
      }
      else {
        this.currentDataList = this.dataList.filter(item => item.fileType == fileType)
        this.searchCurrentCategoryList = new Set(this.currentDataList.map(item => item.fileTypeDetail));
      }
    },
    // 根据二级类别筛选（自动调用一级）
    filterByTypePlus(fileType, fileTypeDetail) {
      if (fileTypeDetail.length == 0) {
        this.filterByType(fileType)
      } else {
        this.currentDataList = this.dataList
          .filter(item => item.fileType == fileType)
          .filter(item => item.fileTypeDetail == fileTypeDetail)
      }
    },
    // 模糊搜索（同时传入一、二级）
    filterByName(newVal) {
      this.filterByTypePlus(this.searchCategory,this.searchCurrentCategory)
      if (newVal.length != 0) {
        let resArr = []
        this.filterByTypePlus(this.searchCategory,this.searchCurrentCategory)
        this.currentDataList.filter(item => {
          for (let i in item) {
            if (String(item[i]).indexOf(newVal)>=0) {
              if (resArr.indexOf(item)==-1)
                resArr.push(item);
            }
          }
        })
        this.currentDataList =resArr
      }
    },
    deleteItem(fileId) {
      post("fileHost/delete/" + fileId)
        .then(() => {
            this.$buefy.toast.open('删除文件ID：' + fileId)
            this.dataList = this.dataList.filter(t => t.id != fileId)
            this.currentDataList = this.currentDataList.filter(t => t.id != fileId)
          }
        )
        .catch(() => this.$buefy.toast.open('删除失败'))
    },
    confirmCustomDelete(fileId) {
      this.$buefy.dialog.confirm({
        title: '删除操作',
        message: '是否确认<b>删除</b>这条数据？',
        confirmText: '确认删除',
        cancelText: '取消',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteItem(fileId)
      })
    }
  },
  mounted() {
    get("fileHost/getFilePublicInfo")
      .then(e => {
        this.dataList = e.data
        this.currentDataList = this.dataList
        this.searchCategoryList = new Set(this.currentDataList.map(item => item.fileType));
      })
  }
}
</script>

<style scoped>

</style>
